import Scheduler, { SchedulerTypes } from "devextreme-react/scheduler";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DataSource from "devextreme/data/data_source";
import "./agenda.scss";

const Agenda = () => {
  const currentDate = useMemo(() => new Date(), []);
  const views: SchedulerTypes.ViewType[] = ["day", "week", "month"];

  const [currentView, setCurrentView] = useState("week");
  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      0,
      0,
      0
    ),
    endDate: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      23,
      59,
      59
    ),
  });

  const tokenData = sessionStorage.getItem("tokenData");
  const initialRender = useRef(true);

  const adjustDateForTimezone = (date: Date) => {
    const localDate = new Date(date);
    const offset = localDate.getTimezoneOffset() * 60000;
    return new Date(localDate.getTime() - offset);
  };

  const updateDateRange = useCallback(
    (view: any, startDate: string | number | Date) => {
      let start = new Date(startDate);
      let end: Date;

      if (isNaN(start.getTime())) {
        console.error("Invalid startDate:", startDate);
        return;
      }

      start = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        0,
        0,
        0
      );
      start = adjustDateForTimezone(start);

      switch (view) {
        case "day":
          end = new Date(startDate);
          end.setHours(23, 59, 59, 999);
          break;
        case "week":
          const dayOfWeek = start.getDay();
          const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;

          start.setDate(start.getDate() + diffToMonday);
          start.setHours(0, 0, 0, 0);
          start = adjustDateForTimezone(start);
          end = new Date(start);
          end.setDate(end.getDate() + 6);
          end.setHours(23, 59, 59, 999);
          end.setHours(23, 59, 59, 999);
          break;
        case "month":
          start = new Date(start.getFullYear(), start.getMonth(), 1);
          start = adjustDateForTimezone(start);

          end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
          end.setHours(23, 59, 59, 999);
          break;
        default:
          console.error("Invalid view:", view);
          end = start;
          break;
      }

      end = adjustDateForTimezone(end);

      setDateRange({ startDate: start, endDate: end });
    },
    [setDateRange]
  );

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      updateDateRange(currentView, currentDate);
    }
  }, [currentView, currentDate, updateDateRange]);

  const dataSource = useMemo(() => {
    return new DataSource({
      load: () => {
        const { startDate, endDate } = dateRange;

        console.log(`https://lio-2024.azurewebsites.net/api/odata/Production?$filter=DateRdvDebut ge ${startDate.toISOString()} and DateRdvFin le ${endDate.toISOString()}&$expand=IdContactNavigation($select=Nom)&$expand=IdElementNavigation($select=CodeTypeElement,Designation)`)

        return fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Production?$filter=DateRdvDebut ge ${startDate.toISOString()} and DateRdvFin le ${endDate.toISOString()}&$expand=IdContactNavigation($select=Nom)&$expand=IdElementNavigation($select=CodeTypeElement,Designation)`,
          {
            headers: {
              Authorization: `Bearer ${tokenData}`,
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => data.value)
          .catch((error) => {
            console.error("Error fetching data for Scheduler:", error);
            return [];
          });
      },
      key: "Oid",
      byKey: async (key) => {
        try {
          const response = await fetch(
            `https://lio-2024.azurewebsites.net/api/odata/Production(${key})`,
            {
              headers: {
                Authorization: `Bearer ${tokenData}`,
              },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          if (!data) {
            throw new Error("No data found for the provided key.");
          }
          return data;
        } catch (error) {
          console.error("Error in byKey method:", error);
          return null;
        }
      },
      update: async (key, values) => {
        if (!tokenData) {
          throw new Error("Token manquant. Veuillez vous reconnecter.");
        }
        const updateValues: any = {
          Libelle: values.Libelle,
          DateRdvDebut: values.DateRdvDebut,
          DateRdvFin: values.DateRdvFin,
          Description: values.Description,
          IdContact: values.IdContact,
          IdElement: values.IdElement,
          IdTiers: values.IdTiers,
          IdTiersAdresseProduction: values.IdTiersAdresseProduction,
          IdProjet: values.IdProjet
        };

        if (values.IdTiers && values.IdContact) {
          updateValues.IdTiersContact = await fetchIdTiersContact(
            values.IdTiers,
            values.IdContact,
            tokenData
          );
        }

        return fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Production(${key})`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenData}`,
            },
            body: JSON.stringify(updateValues),
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            if (response.status === 204) {
              return { key, ...updateValues };
            }
            return response.json();
          })
          .then((updatedData) => {
            return updatedData;
          })
          .catch((error) => {
            throw error;
          });
      },
      remove: (key) => {
        return fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Production(${key})`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${tokenData}`,
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return key;
          })
          .catch((error) => {
            console.error("Error deleting data for Scheduler:", error);
            throw error;
          });
      },
      insert: async (values) => {
        if (!tokenData) {
          throw new Error("Token manquant. Veuillez vous reconnecter.");
        }

        const now = new Date();
        const offset = now.getTimezoneOffset();
        const localDate = new Date(now.getTime() - offset * 60000);
  
        const insertValues: any = {
          Libelle: values.Libelle,
          DateRdvDebut: values.DateRdvDebut,
          DateRdvFin: values.DateRdvFin,
          Description: values.Description,
          IdContact: values.IdContact,
          IdElement: values.IdElement,
          IdTiers: values.IdTiers,
          IdTiersAdresseProduction: values.IdTiersAdresseProduction,
          IdProjet : values.IdProjet,

          // Valeur en dur
          IdDossier: 1,
          IdSite: 1,
          IdProductionGroupe: 1,
          CodeTypeProduction: 1,
          Description2: " ",
          Qte: 1,
          IdCategorieUnite: 1,
          QtePrevue: 1,
          QteRealise: 1,
          QteReste: 1,
          CoutUnitaireRevient: 1,
          PrixUnitaire: 1,
          MontantRevient: 1,
          Montant: 1,
          DateProduction: localDate.toISOString(),
          DateEcheance: localDate.toISOString(),
          IdProductionOrigine: 1,
          IdProductionParent: 1,
          CodeEtatProduction: 1,
          EstTermine: false,
        };
  
        if (values.IdTiers && values.IdContact) {
          insertValues.IdTiersContact = await fetchIdTiersContact(
            values.IdTiers,
            values.IdContact,
            tokenData
          );
        }
 
        return fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Production`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenData}`,
            },
            body: JSON.stringify(insertValues),
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then((newData) => {
            return newData;
          })
          .catch((error) => {
            console.error("Error inserting data for Scheduler:", error);
            throw error;
          });
      },
    });
  }, [dateRange, tokenData]);

  const contactDataSource = useMemo(() => {
    return new DataSource({
      load: async () => {
        try {
          const response = await fetch(
            `https://lio-2024.azurewebsites.net/api/odata/Contact?$filter=CodeCivilite eq 6`,
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return data.value || [];
        } catch (error) {
          console.error("Error fetching contact data:", error);
          return [];
        }
      },
      key: "Oid",
      byKey: async (key) => {
        try {
          const response = await fetch(
            `https://lio-2024.azurewebsites.net/api/odata/Contact(${key})`,
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return data || null;
        } catch (error) {
          console.error("Error fetching contact by key:", error);
          return null;
        }
      },
    });
  }, [tokenData]);

  const elementDataSource = useMemo(() => {
    return new DataSource({
      load: async () => {
        try {
          const response = await fetch(
            `https://lio-2024.azurewebsites.net/api/odata/Element`,
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return data.value || [];
        } catch (error) {
          console.log("Error fetching element data:", error);
          return [];
        }
      },
      key: "Oid",
      byKey: async (key) => {
        try {
          const response = await fetch(
            `https://lio-2024.azurewebsites.net/api/odata/Element(${key})`,
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return data || null;
        } catch (error) {
          console.error("Error fetching element by key:", error);
          return null;
        }
      },
    });
  }, [tokenData]);

  const tiersDataSource = useMemo(() => {
    return new DataSource({
      load: async () => {
        try {
          const response = await fetch(
            `https://lio-2024.azurewebsites.net/api/odata/Tier?$filter=RaisonSociale eq 'ACS'`,
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return data.value || [];
        } catch (error) {
          console.error("Error fetching tiers data:", error);
          return [];
        }
      },
      key: "Oid",
      byKey: async (key) => {
        try {
          const response = await fetch(
            `https://lio-2024.azurewebsites.net/api/odata/Tier(${key})`,
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return data || null;
        } catch (error) {
          console.error("Error fetching tiers by key:", error);
          return null;
        }
      },
    });
  }, [tokenData]);

  const fetchIdTiersContact = async (
    idTiers: number,
    idContact: number,
    token: string
  ) => {
    try {
      const response = await fetch(
        `https://lio-2024.azurewebsites.net/api/odata/TiersContact?$filter=IdTiers eq ${idTiers} and IdContact eq ${idContact}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Erreur HTTP! Status: ${response.status}`);
      }
      const data = await response.json();

      if (data && data.value && data.value.length > 0) {
        return data.value[0].Oid;
      }
      throw new Error("Aucune correspondance trouvée pour IdTiersContact.");
    } catch (error) {
      console.error("Erreur lors de la recherche d'IdTiersContact :", error);
      throw error;
    }
  };

  const tiersAdresseDataSource = useMemo(() => {
    return new DataSource({
      load: async () => {
        try {
          const response = await fetch(
            `https://lio-2024.azurewebsites.net/api/odata/TiersAdresse?$filter=AdresseNom eq 'ACS'`,
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return data.value || [];
        } catch (error) {
          console.error("Error fetching tiers adresse data:", error);
          return [];
        }
      },
      key: "Oid",
      byKey: async (key) => {
        try {
          const response = await fetch(
            `https://lio-2024.azurewebsites.net/api/odata/TiersAdresse(${key})`,
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return data || null;
        } catch (error) {
          console.error("Error fetching tiers adresse by key:", error);
          return null;
        }
      },
    });
  }, [tokenData]);

  const projetDataSource = useMemo(() => {
    return new DataSource({
      load: async () => {
        try {
          const response = await fetch(
            `https://lio-2024.azurewebsites.net/api/odata/Projet`,
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return data.value || [];
        } catch (error) {
          console.error("Error fetching projet data:", error);
          return [];
        }
      },
      key: "Oid",
      byKey: async (key) => {
        try {
          const response = await fetch(
            `https://lio-2024.azurewebsites.net/api/odata/Projet(${key})`,
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return data || null;
        } catch (error) {
          console.error("Error fetching projet by key:", error);
          return null;
        }
      },
    });
  }, [tokenData]);

  return (
    <>
      <Scheduler
        timeZone="Europe/Paris"
        dataSource={dataSource}
        views={views}
        defaultCurrentView="week"
        defaultCurrentDate={currentDate}
        height={730}
        startDayHour={9}
        endDayHour={19}
        dateSerializationFormat="yyyy-MM-ddTHH:mm:ssZ"
        remoteFiltering={true}
        textExpr="Libelle"
        startDateExpr="DateRdvDebut"
        endDateExpr="DateRdvFin"
        descriptionExpr="Description"
        firstDayOfWeek={1}
        onOptionChanged={(e) => {
          if (e.name === "currentView") {
            setCurrentView(e.value);
            updateDateRange(e.value, currentDate);
          } else if (e.name === "currentDate") {
            updateDateRange(currentView, e.value);
          }
        }}
        resources={[
          {
            fieldExpr: "IdContact",
            dataSource: contactDataSource,
            valueExpr: "Oid",
            displayExpr: "Nom",
            label: "Contact",
          },
          {
            fieldExpr: "IdElement",
            dataSource: elementDataSource,
            valueExpr: "Oid",
            displayExpr: (element) => {
              const code = element?.CodeTypeElement || "";
              const designation = element?.Designation || "";
              return `${code} - ${designation}`;
            },
            label: "Element",
          },
          {
            fieldExpr: "IdTiers",
            dataSource: tiersDataSource,
            valueExpr: "Oid",
            displayExpr: "NomAbrege",
            label: "Tiers",
          },
          {
            fieldExpr: "IdTiersAdresseProduction",
            dataSource: tiersAdresseDataSource,
            valueExpr: "Oid",
            displayExpr: "Adresse1",
            label: "Adresse",
          },
          {
            fieldExpr: "IdProjet",
            dataSource: projetDataSource,
            valueExpr: "Oid",
            displayExpr: "RefProjet",
            label: "Projet"
          }
        ]}
      />
    </>
  );
};

export default Agenda;
