import { HomePage, TasksPage, ProfilePage, TiersPage, TreeListPage, ProductionPage, AgendaPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/tasks',
        element: TasksPage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/tiers',
        element: TiersPage
    },
    {
        path: '/treelist',
        element: TreeListPage
    },
    {
        path: '/production',
        element: ProductionPage
    },
    {
        path: '/agenda',
        element: AgendaPage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
