import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  Scrolling,
  Editing,
  Paging,
  Pager,
  DataGridTypes,
  Export,
  ColumnChooser,
  SearchPanel,
  Sorting,
  StateStoring,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { useCallback, useEffect, useState } from "react";
import './tiers.scss'
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";

interface TypeTiers {
  CodeType: string;
  NomType: string;
}

interface TypeCategorie {
  CategorieNom: string;
  CodeTypeCategorie: number;
}

interface TypeYParamEtat {
  CodeType: number;
  NomEtat: string;
}

const Tiers = () => {
  const [typeTiersData, setTypeTiersData] = useState<TypeTiers[]>([]);
  const [categorieData, setCategorieData] = useState<TypeCategorie[]>([]);
  const [yParamEtat, setYParamEtat] = useState<TypeYParamEtat[]>([]);
  const tokenData = sessionStorage.getItem("tokenData");
  const [searchText, setSearchText] = useState("");


  const [isSearchPanel, setIsSearchPanel] = useState(false);

  useEffect(() => {
    const fetchTypeTiersData = async () => {
      try {
        const response = await fetch(
          "https://lio-2024.azurewebsites.net/api/odata/ZsysType",
          {
            headers: {
              Authorization: `Bearer ${tokenData}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setTypeTiersData(data.value);
        } else {
          console.error("Erreur de chargement des types:", response.statusText);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des types:", error);
      }
    };

    const fetchCategoriData = async () => {
      try {
        const response = await fetch(
          "https://lio-2024.azurewebsites.net/api/odata/Categorie",
          {
            headers: {
              Authorization: `Bearer ${tokenData}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setCategorieData(data.value);
        } else {
          console.error(
            "Erreur de chargement des categories:",
            response.statusText
          );
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des categories:", error);
      }
    };

    const fetchYParamEtat = async () => {
      try {
        const response = await fetch(
          "https://lio-2024.azurewebsites.net/api/odata/YparamEtat",
          {
            headers: {
              Authorization: `Bearer ${tokenData}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setYParamEtat(data.value);
        } else {
          console.error(
            "Erreur lors de la récupération des YParamEtat:",
            response.statusText
          );
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des YParamEtat:", error);
      }
    };

    fetchTypeTiersData();
    fetchCategoriData();
    fetchYParamEtat();
  }, [tokenData]);

  const transformFilter = (filter: any, isSearchPanel: boolean): string => {
    if (Array.isArray(filter)) {
      if (typeof filter[0] === "string" && filter.length === 3) {
        const [field, operator, value] = filter;

        const propertyPath = field.replace(/\./g, "/");
        switch (operator) {
          case "contains":
            return `contains(${propertyPath},'${value}')`;
          case "notcontains":
            return `not(contains(${propertyPath},'${value}'))`;
          case "startswith":
            return `startswith(${propertyPath},'${value}')`;
          case "endswith":
            return `endswith(${propertyPath},'${value}')`;
          case "=":
            return `${propertyPath} eq '${value}'`;
          case "<>":
            return `${propertyPath} ne '${value}'`;
          case ">":
            return `${propertyPath} gt '${value}'`;
          case ">=":
            return `${propertyPath} ge '${value}'`;
          case "<":
            return `${propertyPath} lt '${value}'`;
          case "<=":
            return `${propertyPath} le '${value}'`;
          default:
            return `${propertyPath} ${operator} '${value}'`;
        }
      }

      const subFilters = filter
      .map((subFilter: any) => transformFilter(subFilter, isSearchPanel))
      .filter(Boolean);

    if (subFilters.length > 1) {
      return `(${subFilters.join(isSearchPanel ? " or " : " and ")})`;
    }
    return subFilters[0];
  }
    return "";
  };


  const dataSource = new CustomStore({
    key: "Oid",
    load: async (loadOptions) => {
      const params = [];

      if (loadOptions.sort && Array.isArray(loadOptions.sort)) {
        const sort = loadOptions.sort
          .map((s) => {
            if (typeof s === "object" && typeof s.selector === "string") {
              const propertyPath = s.selector.replace(/\./g, "/");
              return `${propertyPath} ${s.desc ? "desc" : "asc"}`;
            }
            return "";
          })
          .filter(Boolean)
          .join(",");

        if (sort) {
          params.push(`$orderby=${sort}`);
        }
      }

      // Filtre
      if (loadOptions.filter) {
        const filter = transformFilter(loadOptions.filter, isSearchPanel);
        if (filter) {
          params.push(`$filter=${filter}`);
        }
      }

      // Pagination
      if (loadOptions.skip !== undefined) {
        params.push(`$skip=${loadOptions.skip}`);
      }
      if (loadOptions.take !== undefined) {
        params.push(`$top=${loadOptions.take}`);
      }

      // Extension de la table Contact
      // Faire une recherche dans metadata pour connaître les noms des relations
      // Permet seulement de sélectionner la colonne Mobile sinon enlever ($select=Mobile) pour sélectionner toutes les colonnes de Contact
      params.push("$expand=IdContactPrincipalNavigation($select=Mobile,Nom)");
      params.push(
        "$expand=IdAdresseFacturationPrincipaleNavigation($select=AdresseCp,AdresseVille)"
      );
      params.push("$count=true");

      const queryStringParams = params.length > 0 ? `?${params.join("&")}` : "";

      try {
        const response = await fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Tier${queryStringParams}`,
          {
            headers: {
              Authorization: `Bearer ${tokenData}`,
            },
          }
        );
        if (response.ok) {
          
          const data = await response.json();
          return {
            data: data.value,
            totalCount: data["@odata.count"],
          };
        } else {
          const errorText = await response.text();
          throw new Error(
            `Erreur de chargement des données : ${response.status} - ${response.statusText}: ${errorText}`
          );
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    update: async (key, values) => {
      try {
        const response = await fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Tier(${key})`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenData}`,
            },
            body: JSON.stringify(values),
          }
        );
        if (!response.ok) {
          throw new Error("Erreur de Update");
        }
      } catch (err) {
        throw err;
      }
    },
    remove: async (key) => {
      try {
        const response = await fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Tier(${key})`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${tokenData}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `Erreur suppression : ${response.status} - ${response.statusText}: ${errorText}`
          );
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    insert: async (values) => {
      try {
        const response = await fetch(
          "https://lio-2024.azurewebsites.net/api/odata/Tier",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenData}`,
            },
            body: JSON.stringify(values),
          }
        );
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `Erreur d'insertion : ${response.status} - ${response.statusText}: ${errorText}`
          );
        }
      } catch (err) {
        throw err;
      }
    },
  });

  const getNomTypeById = (typeTiersId: any) => {
    const typeTiers = typeTiersData.find(
      (type) => type.CodeType === typeTiersId
    );
    return typeTiers ? typeTiers.NomType : "";
  };

  const getCategorieNomById = (categorieId: any) => {
    const categorie = categorieData.find(
      (cat) => cat.CodeTypeCategorie === categorieId
    );
    return categorie ? categorie.CategorieNom : "";
  };

  const getYParamEtatById = (etatId: any) => {
    const etat = yParamEtat.find((e) => e.CodeType === etatId);
    return etat ? etat.NomEtat : "";
  };

  const onExporting = (e: DataGridTypes.ExportingEvent) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "Export Tiers.xlsx"
        );
      });
    });
  };

  const RowChangeAndSave = async (gridInstance: any) => {
    try {
      await gridInstance.saveEditData();
    } catch (error) {
      console.error("Erreur lors de la sauvegarde :", error);
    }
  };

  const onOptionChanged = useCallback((e: any) => {
    if (e.fullName === "searchPanel.text") {
      setSearchText(e.value);
      setIsSearchPanel(true);
    }
  }, []);

  return (
    <DataGrid
      dataSource={dataSource}
      showBorders={true}
      width="100%"
      height="80vh"
      remoteOperations={true}
      allowColumnResizing={true}
      onExporting={onExporting}
      allowColumnReordering={true}
      focusedRowEnabled={true}
      onFocusedRowChanged={(e) => RowChangeAndSave(e.component)}
      onOptionChanged={onOptionChanged}

    >

      <StateStoring enabled={true} type="localStorage" storageKey="storage" />

      <ColumnChooser enabled={true} />

      <FilterRow visible={true} />

      <Scrolling mode="standard" />

      <Sorting mode="multiple" />

      <Editing
        mode="batch"
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
      />

      <Paging defaultPageSize={10} />

      <Pager
        visible={true}
        showPageSizeSelector={true}
        allowedPageSizes={[10, 20, 50]}
        displayMode="full"
        showInfo={true}
        showNavigationButtons={true}
      />
      
      <SearchPanel highlightSearchText={true} visible={true} width={200} placeholder="Search..." text={searchText} />
      
      <Column dataField="RefTiers" caption="Ref Tiers" dataType="string" allowSearch={true} allowFiltering={true} />
      
      <Column
        dataField="TypeTiers"
        caption="TypeTiers"
        calculateDisplayValue={(rowData) => getNomTypeById(rowData.TypeTiers)}
        lookup={{
          dataSource: typeTiersData,
          valueExpr: "CodeType",
          displayExpr: "NomType",
        }}
        allowSorting={false}
        allowFiltering={false}
        allowEditing={true}
        allowSearch={true}
      />

      <Column
        dataField="IdCategorieTiers"
        caption="Categorie Tiers"
        calculateDisplayValue={(rowData) =>
          getCategorieNomById(rowData.IdCategorieTiers)
        }
        lookup={{
          dataSource: categorieData,
          valueExpr: "CodeTypeCategorie",
          displayExpr: "CategorieNom",
        }}
        allowSorting={true}
        allowFiltering={true}
        allowSearch={true}
      />

      <Column dataField="RaisonSociale" caption="Raison Sociale" allowSearch={true} />

      <Column dataField="NomAbrege" caption="Nom Abrege" allowSearch={true} />

      <Column
        dataField="CodeEtatTiers"
        caption="Etat Tiers"
        calculateDisplayValue={(rowData) =>
          getYParamEtatById(rowData.CodeEtatTiers)
        }
        lookup={{
          dataSource: yParamEtat,
          valueExpr: "CodeType",
          displayExpr: "NomEtat",
        }}
        allowSorting={true}
        allowFiltering={true}
        visible={false}
        allowSearch={true}
      />

      <Column
        dataField="IdContactPrincipalNavigation.Nom"
        caption="Nom Contact"
        allowEditing={false}
        allowSearch={true}
      />

      <Column
        dataField="IdContactPrincipalNavigation.Mobile"
        caption="Mobile"
        allowEditing={false}
        allowSearch={true}
      />

      <Column
        dataField="IdAdresseFacturationPrincipaleNavigation.AdresseCp"
        caption="Code Postal"
        allowEditing={false}
        allowSearch={true}
      />

      <Column
        dataField="IdAdresseFacturationPrincipaleNavigation.AdresseVille"
        caption="Ville"
        allowEditing={false}
        allowSearch={true}
      />

      <Export enabled={true} allowExportSelectedData={false} />

    </DataGrid>
  );
};

export default Tiers;
