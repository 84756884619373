import DataGrid, { Column, Editing, FilterRow, Pager, Paging, Scrolling } from "devextreme-react/cjs/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "devextreme/data/odata/store";
import './production.css';

const Production = () => {

  const tokenData = sessionStorage.getItem("tokenData");

  const transformFilter = (filter: any): string => {
    if (Array.isArray(filter)) {
      if (typeof filter[0] === "string" && filter.length === 3) {
        let [field, operator, value] = filter;
  
        const propertyPath = field.replace(/\./g, "/");
  
        if (value instanceof Date) {
          value = value.toISOString();
        } else if (typeof value === "string" && !isNaN(Date.parse(value))) {
          value = new Date(value).toISOString();
        } else if (!isNaN(value)) {
          value = parseFloat(value);
        }
  
        switch (operator) {
          case "=":
            return `${propertyPath} eq '${value}'`;
          case "<>":
            return `${propertyPath} ne '${value}'`;
          case ">":
            return `${propertyPath} gt ${value}`;
          case ">=":
            return `${propertyPath} ge ${value}`;
          case "<":
            return `${propertyPath} lt ${value}`;
          case "<=":
            return `${propertyPath} le ${value}`;
          case "contains":
            return `contains(${propertyPath}, '${value}')`;
          case "notcontains":
            return `not(contains(${propertyPath}, '${value}'))`;
          case "startswith":
            return `startswith(${propertyPath}, '${value}')`;
          case "endswith":
            return `endswith(${propertyPath}, '${value}')`;
          default:
            return `${propertyPath} ${operator} '${value}'`;
        }
      }
  
      const subFilters = filter
        .map((subFilter) => transformFilter(subFilter))
        .filter(Boolean);
  
      if (subFilters.length > 1) {
        return `(${subFilters.join(" and ")})`;
      }
      return subFilters[0];
    }
    return "";
  };

  const dataSource = new CustomStore({
    key: "Oid",
    load: async (loadOptions) => {
      const params = [];
      let totalCount = 0;

      try {
        const response = await fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Production?$count=true&$top=0`, {
            headers: {
              Authorization: `Bearer ${tokenData}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          totalCount = data["@odata.count"];
        } else {
          const errorText = await response.text();
          throw new Error(
            `Erreur de chargement du compte : ${response.status} - ${response.statusText}: ${errorText}`
          );
        }

      } catch (err) {
        console.error(err);
        throw err;
      }

      if (loadOptions.sort && Array.isArray(loadOptions.sort)) {
        const sort = loadOptions.sort
          .map((s) => {
            if (typeof s === "object" && typeof s.selector === "string") {
              const propertyPath = s.selector.replace(/\./g, "/");
              return `${propertyPath} ${s.desc ? "desc" : "asc"}`;
            }
            return "";
          })
          .filter(Boolean)
          .join(",");

        if (sort) {
          params.push(`$orderby=${sort}`);
        }
      }

      // Filtre
      if (loadOptions.filter) {
        const filter = transformFilter(loadOptions.filter);
        if (filter) {
          params.push(`$filter=${filter}`);
        }
      }

      // Pagination
      if (loadOptions.skip !== undefined) {
        params.push(`$skip=${loadOptions.skip}`);
      }
      if (loadOptions.take !== undefined) {
        params.push(`$top=${loadOptions.take}`);
      }

      params.push("$expand=IdContactNavigation($select=Nom)");
      params.push("$expand=IdTiersNavigation($select=RefTiers,RaisonSociale)");

      const queryStringParams = params.length > 0 ? `?${params.join("&")}` : "";

      try {
        const response = await fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Production${queryStringParams}`,
          {
            headers: {
              Authorization: `Bearer ${tokenData}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          return {
            data: data.value,
            totalCount: totalCount,
          }
        } else {
          const errorText = await response.text();
          throw new Error(
            `Erreur de chargement des données : ${response.status} - ${response.statusText}: ${errorText}`
          );
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    update: async (key, values) => {
      try {
        const response = await fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Production(${key})`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenData}`,
            },
            body: JSON.stringify(values),
          }
        );
        if (!response.ok) {
          throw new Error("Erreur de Update");
        }
      } catch (err) {
        throw err;
      }
    },

    remove: async (key) => {
      try {
        const response = await fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Production(${key})`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${tokenData}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `Erreur suppression : ${response.status} - ${response.statusText}: ${errorText}`
          );
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    insert: async (values) => {
      try {
        const response = await fetch(
          `https://lio-2024.azurewebsites.net/api/odata/Production`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenData}`,
            },
            body: JSON.stringify(values),
          }
        );
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `Erreur d'insertion : ${response.status} - ${response.statusText}: ${errorText}`
          );
        }
      } catch (err) {
        throw err;
      }
    },
  })

  return (
    <DataGrid
      dataSource={dataSource}
      showBorders={true}
      width="100%"
      height="80vh"
      remoteOperations={true}
      allowColumnResizing={true}
      // columnAutoWidth={true}
    >
      <FilterRow visible={true} />
      <Scrolling mode="standard" />
      <Editing
        mode="row"
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
      />
      <Paging defaultPageSize={10} />
      <Pager
        visible={true}
        showPageSizeSelector={true}
        allowedPageSizes={[10, 20, 50]}
        displayMode="full"
        showInfo={true}
        showNavigationButtons={true}
      />
      
      <Column dataType="date" dataField="DateProduction" caption="Date début" format="d/M/yyyy" />
      <Column dataType="date" dataField="DateEcheance" caption="Date fin" format="d/M/yyyy" />
      <Column dataField="Libelle" caption="Libelle" dataType="string" cellRender={(cellData) => (
        <div className="multiline-cell">
          {cellData.value}
        </div>
        )}
      />
      <Column dataField="Description" caption="Description" dataType="string" />
      <Column dataField="Description2" caption="Destinataire" dataType="string" />

      
      <Column dataField="IdContactNavigation.Nom" caption="Nom contact" allowEditing={false} />
      <Column dataField="IdTiersNavigation.RefTiers" caption="Ref Tiers" allowEditing={false} />
      <Column dataField="IdTiersNavigation.RaisonSociale" caption="Raison Sociale" allowEditing={false} />
    </DataGrid>
  );
};

export default Production;
